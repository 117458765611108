.global-nav-header-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  height: 74px;

  &.offset-for-sidenav {
    top: 0;
  }

  .MuiAppBar-root {
    background-color: #FFFFFF;
    flex-direction: unset;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
  }

  .global-nav-header-app-bar {
    min-height: 100%;
    max-height: 100%;
  }

  .global-nav-header-title {
    color: #697FF7;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  }

  .MuiFormControl-root {
    margin: 0;
  }

  .global-nav-header-leasing-team-selection-wrapper {
    .MuiFormControl-root {
      margin-top: -6px;
    }

    .MuiInputBase-root {
      width: 240px;
      height: 40px;
      border-radius: 4px;
      padding: 0px;
      margin: 15px 20px 0 20px;
    }

    #global-nav-header-leasing-team-selection {
      padding-left: 8px;
    }

    .MuiAutocomplete-inputRoot:focus-within {
      border: 1px solid #97A6FC;
      background: #FFFFFF;
    }

    .MuiIconButton-root {
      right: 8px;
      top: 4px;
    }

    .MuiInputBase-root,
    .MuiOutlinedInput-input {
      outline: none;
      border-radius: 4px;
      background: #F5F5F5;

      &:focus {
        background: #ffffff;
      }
    }

    input,
    input:hover {
      cursor: pointer;
    }

    fieldset,
    fieldset:hover,
    .MuiInputBase-root {
      border: none;
    }

    .MuiSvgIcon-root,
    #global-nav-header-leasing-team-selection {
      color: #000000;
    }

    .MuiIconButton-root[title=Clear] {
      display: none;
    }
  }
}

:root {
  --header-color-background: #ffffff;
  --header-color-text: #4D5066;
  --header-item-color-background-hover: white;
  --header-item-color-background-active: #f5f6ff;
  --scope-product-color-text-active: #000000;
  --header-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
  --menu-item-icon-color:#4D5066;
  --menu-item-background-color-hover:#f5f6ff;
  --menu-item-background-color-active: #f5f6ff;
  --menu-item-dropdown-heading-color-background:#ffffff;
  --menu-item-dropdown-item-color-border:#ffffff;
  // --user-menu-color-background-hover:#F5F6FF;
}

omnibar-shell {
  .o-navigation-toggle {
    display: none !important;
  }

  .o-logo {
    padding-left: 18px !important;
  }

  .o-icon-menu__custom {
    order: 1;
  }

  .o-icon-menu__switcher {
    order: 2;
  }

  .o-icon-menu__help {
    order: 3;
  }
  omnibar-user-menu {
    .o-user__name {
      color: #000000;
      font-weight: 500;
      opacity: 0.8;
    }
  }
}

omnibar-scope {
  .o-option__check-mark {
    display: none !important;
  }

  .o-option__text__title {
    white-space: wrap;
  }

  .o-scope__arrow {
    font-size: 1rem;
  }

  omnibar-select {
    .o-option {
      font-size: 12px;
      margin: 5px 0 5px 0;
      border: 0;
      padding-right:5px;
    }

    .o-select {
      margin-left: 10px;
    }

    .o-scope__product {
      color:#292B3D;
      font-size:12px;
    }

    .o-scope--selector {
      height: 40px;
      border-radius: 4px;
    }

    .o-option__text {
      --text-opacity: black
    }

    .o-select .o-select__toggle .o-scope--selector:hover {
      background: #f5f6ff;
      border-radius: 4px;
    }

    .o-select .o-select__toggle:focus {
      border-bottom: 2px solid #97A6FC;
      border-radius: 4px;
    }

    .o-selectedTeam {
      pointer-events: none;
    }

    .o-disableText {
      color: grey;
    }
  }
}

omnibar-unified-switcher{
  .o-unified-switcher{
    height: 56px;
  }
  .o-unified-switcher__app{
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-top: -1px;
    font-size: 14px;
    border-top:#FFFFFF;
  }
  .o-unified-switcher__name{
    color: #000000;
  }
}

#oHelp svg path {
  transform: scale(0.9724);
  transform-origin: center;
}
#oSwitcher svg path {
  transform: scale(0.8336, 1.061);
  transform-origin: center;
}
.o-omnibar .o-unified-menu .o-icon-menu .omnibar-unified-menu-item {
  width: 56px;
  height: 56px;
}
 omnibar-user-menu {
  .o-user__info {
    padding: 10px 18px !important;
  }
  .o-user__info:hover{
    background-color: #F5F6FF;
  }
    .o-user__name {
      font-weight: 500;
      font-family: Open Sans;
      line-height: 18px;
    font-size: 12px;
  }
}

.o-omnibar .o-unified-menu{
  align-items: center;
}

button.o-unified-switcher__app {
  outline: none !important;
}
button.o-unified-switcher__app:hover {
  background-color: #F5F6FF;
}
@media print {
  .global-nav-header-container {
    position: unset;
    right: unset;
    left: unset;
  }
}
